const jQuery = require('jquery');
const $ = jQuery;

$('#myForm').on('submit', function (event) {
    event.preventDefault(); // Prevent form from submitting normally

    // get form data
    const formData = new FormData(this);
    const formDataObj = {};
    formData.forEach((val, key) => {
        formDataObj[key] = val;
    });
    const formDataJson = JSON.stringify(formDataObj);


    switch ($(this).attr('data-type')) {
        case 'user':

            fetch('/login', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: formDataJson,
            })
                .then(async response => {
                    if (response.redirected) {
                        // Handle redirect response
                        window.location.href = response.url;
                    }
                    else {
                        $('#result').text(await response.text()); // Update DOM with result
                    }
                })
                .catch(error => console.error('Error:', error));

            break;
        case 'admin':

            fetch('/login/admin', {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: formDataJson,
            })
                .then(async response => {
                    if (response.redirected) {
                        // Handle redirect response
                        window.location.href = response.url;
                    }
                    else {
                        $('#result').text(await response.text()); // Update DOM with result
                    }
                })
                .catch(error => console.error('Error:', error));

            break;
    }
});